import axios from 'axios';
import Cookies from 'js-cookie';

import tokenFetcher from 'zg_utils/tokens';

const baseURL = '/api/v1/';

const addAuthorization = apiCall =>
  apiCall.interceptors.request.use(async config => {
    config.headers['X-CSRFToken'] = Cookies.get('csrftoken');

    const accessToken = await tokenFetcher.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

const client = axios.create({
  baseURL,
  responseType: 'json',
  headers: {
    'X-REQUESTED-WITH': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
});

addAuthorization(client);

export const CLIENT_POST_FILE = axios.create({
  baseURL,
  responseType: 'json',
});

addAuthorization(CLIENT_POST_FILE);

export const CLIENT_GET_FILE = axios.create({
  baseURL,
  responseType: 'blob',
});

addAuthorization(CLIENT_GET_FILE);

export default client;
