import client, { CLIENT_GET_FILE, CLIENT_POST_FILE } from 'api/client';

import createURLParams from 'utils/urls';

export const getFirmById = firmId => client.get(`firms/${firmId}/`);

export const populateFirmStore = firmId => client.get(`firms/${firmId}?for_store=True`);

export const getFilteredFirms = (
  locationCode,
  name,
  attorneyCode,
  cityOrZip,
  phoneNumber,
  state,
  county,
  languages,
  services,
  status,
) => {
  const params = createURLParams({
    name,
    state,
    county,
    languages,
    services,
    attorney_code: attorneyCode,
    city_or_zip: cityOrZip,
    phone: phoneNumber,
    firm_status: status,
    location_code:
      locationCode && locationCode !== 'All'
        ? locationCode.substring(0, locationCode.indexOf(' '))
        : '',
  });
  return client.get('firms/', { params });
};

export const getFirms = async ({
  locationCode,
  nameOrPhone,
  attorneyCode,
  cityOrZipOrCounty,
  state,
  languages,
  services,
  status,
  distance,
}) => {
  const params = createURLParams({
    location_code: locationCode,
    name_or_phone: nameOrPhone,
    attorney_code: attorneyCode,
    city_or_zip_or_county: cityOrZipOrCounty,
    firm_status: status,
    state,
    languages,
    services,
    distance,
  });
  const { data } = await client.get('firms/', { params });
  return data;
};

export const getFilteredMasterFileRows = (
  locationCode,
  name,
  attorneyCode,
  cityOrZip,
  phoneNumber,
  state,
  county,
  languages,
  services,
  status,
) => {
  const params = createURLParams({
    name,
    state,
    county,
    languages,
    services,
    attorney_code: attorneyCode,
    city_or_zip: cityOrZip,
    phone: phoneNumber,
    firm_status: status,
    location_code:
      locationCode && locationCode !== 'All'
        ? locationCode.substring(0, locationCode.indexOf(' '))
        : '',
  });
  return client.get('firms/master-file-row/', { params });
};

export const getMasterFileFirms = async ({
  locationCode,
  nameOrPhone,
  attorneyCode,
  cityOrZipOrCounty,
  state,
  languages,
  services,
  status,
  distance,
}) => {
  const params = createURLParams({
    location_code: locationCode,
    name_or_phone: nameOrPhone,
    attorney_code: attorneyCode,
    city_or_zip_or_county: cityOrZipOrCounty,
    firm_status: status,
    state,
    languages,
    services,
    distance,
  });
  const { data } = await client.get('firms/master-file-row/', { params });
  return data;
};

export const findAndUpdateFirm = (id, payload) => client.patch(`firms/${id}/`, payload);

export const fetchFirmFromMasterFileRow = () =>
  client.get('firms/master-file-row/fetch-master-file-row-firm/');

export const addFirm = payload => client.post('firms/', payload);

export const validateFirmData = payload => client.post('firms/validate-firm/', payload);

export const hasPaymentInfo = pk => client.get(`firms/${pk}/has-payment-info/`);

export const hasSubmittedMalpractice = firm =>
  client.get('firms/has-submitted-malpractice/', { firm });

export const createMalpracticeDocument = payload =>
  CLIENT_POST_FILE.post('firms/malpractice-documents/', payload);

export const getMalpractice = payload => {
  const searchParams = new URLSearchParams(payload);
  return client.get(`firms/malpractice-documents/?${searchParams}`);
};

export const getMalpracticeDocument = id =>
  CLIENT_GET_FILE.get(`firms/malpractice-documents/${id}/`);

export const approveMalpractice = (id, payload) =>
  client.patch(`firms/malpractice-documents/${id}/approve-malpractice/`, payload);

export const denyMalpractice = id =>
  client.patch(`firms/malpractice-documents/${id}/deny-malpractice/`);

export const getFirmOptions = () => client.get('firms/options/');

export const verifyEin = payload => client.post('firms/verify-ein/', payload);

export const deleteAttorneyMasterFileRowByAttorneyCode = payload =>
  client.patch('firms/master-file-row/delete-attorney-master-file-row-by-attorney-code/', payload);

export const getLocationCodes = async () => {
  const { data } = await client.get('firms/location-codes/');
  return data;
};
